@import url(https://use.typekit.net/yaz8boe.css);
html{
  font-size: 40px;
}
body{
  background: #f7f7f7;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card{
  padding: 15px;
}
.btn-controls{
  position: fixed;
  display:flex;
  justify-content: space-between;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,129,69,1) 100%);;
  padding: 20px 20px;
}
button{
  padding: 0px 15px;
  outline: 0px;
  border: 0px;
  border-radius: 4px;
}
.btn-next{
  font-size: 1rem;
}

.btn-prev{
  font-size: 1rem;
}

.btn-refresh{
  font-size: 1rem;
}
.card-title{
  font-weight: 700;
}
.question-options{
  display:flex;
  justify-content: space-between;
}
.icon-refresh{
  cursor:pointer;
}